import axios from './index';

export default {
  getIcdList(keyword) {
    return axios.get('/data/shared/Icd10Async?keyword=' + keyword)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitNewCustomerDiagnose(model) {
    return axios.post('/customerDiagnose/new', model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getDiagnose(id) {
    return axios.get('/customerDiagnose/getCustomerDiagnose?id=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
