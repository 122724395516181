<template>
  <b-container fluid>
    <form>
      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-form-group class="col-md-4" :label="$t('DiagnoseIssueDate')" label-for="issueDate">
              <date-picker v-model="model.issueDate"
                           :tag-name="'diagnoseIssueDate'"
                           :is-required="false" />
            </b-form-group>

            <b-form-group class="col-md-4" :label="$t('Issues')" label-for="�ssues">
              <b-form-textarea v-model="model.issues"></b-form-textarea>
            </b-form-group>

            <b-form-group class="col-md-4" :label="$t('Description')+'/'+$t('Note')" label-for="description">
              <b-form-textarea v-model="model.startDescription"></b-form-textarea>
            </b-form-group>
          </b-row>
        </template>
      </iq-card>

      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-form-group class="col-md-6" :label="$t('Diagnose')" label-for="diagnose">
              <b-form-textarea id="diagnose" v-model="model.diagnose"></b-form-textarea>
            </b-form-group>

            <b-form-group class="col-md-6" :label="$t('PreDiagnose')" label-for="preDiagnose">
              <b-form-textarea id="preDiagnose" v-model="model.preDiagnose"></b-form-textarea>
            </b-form-group>

            <b-form-group class="col-md-6" :label="$t('DiagnoseDate')" label-for="diagnoseDate">
              <date-picker v-model="model.startDate"
                           :tag-name="'diagnoseDate'"
                           :is-required="false" />
            </b-form-group>

            <b-form-group class="col-md-6" :label="$t('Diagnose')+' '+'1'" label-for="firstDiagnose">
              <diagnose-icd-10 @ChangeIcdCode="ChangeIcdCode" :index="1" :value="model.icdCode" :isEdit="isEdit"></diagnose-icd-10>
            </b-form-group>

            <b-form-group class="col-md-6" :label="$t('Diagnose')+' '+'2'" label-for="secondDiagnose">
              <diagnose-icd-10 @ChangeIcdCode="ChangeIcdCode" :index="2" :value="model.icdCode2" :isEdit="isEdit"></diagnose-icd-10>
            </b-form-group>

            <b-form-group class="col-md-6" :label="$t('Diagnose')+' '+'3'" label-for="thirdDiagnose">
              <diagnose-icd-10 @ChangeIcdCode="ChangeIcdCode" :index="3" :value="model.icdCode3" :isEdit="isEdit"></diagnose-icd-10>
            </b-form-group>
          </b-row>
        </template>
      </iq-card>

      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-form-group class="col-md-4" :label="$t('PhysicalExamination')" label-for="physicalExamination">
              <b-form-textarea id="physicalExamination" v-model="model.physicalExamination"></b-form-textarea>
            </b-form-group>

            <b-form-group class="col-md-4" :label="$t('TreatmentDate')" label-for="treatmentDate">
              <date-picker v-model="model.treatmentDate"
                           :tag-name="'diagnosetreatmentDate'"
                           :is-required="false" />
            </b-form-group>

            <b-form-group class="col-md-4" :label="$t('Treatment')" label-for="treatment">
              <b-form-textarea id="treatment" v-model="model.treatment"></b-form-textarea>
            </b-form-group>
          </b-row>
        </template>
      </iq-card>

      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-form-group class="col-md-4" :label="$t('PastTreatments')" label-for="pastTreatments">
              <b-form-textarea id="pastTreatments" v-model="model.pastTreatments"></b-form-textarea>
            </b-form-group>

            <b-form-group class="col-md-4" :label="$t('Medicines')" label-for="medicines">
              <b-form-textarea id="medicines" v-model="model.drugs"></b-form-textarea>
            </b-form-group>

            <b-form-group class="col-md-4" :label="$t('NutritionProgram')" label-for="nutritionProgram">
              <b-form-textarea id="nutritionProgram" v-model="model.nutritionProgram"></b-form-textarea>
            </b-form-group>
          </b-row>
          <b-row>
            <b-form-group class="col-md-6" :label="$t('CV')" label-for="cv">
              <b-form-textarea id="cv" v-model="model.cv"></b-form-textarea>
            </b-form-group>

            <b-form-group class="col-md-6" :label="$t('FamilyCV')" label-for="familyCV">
              <b-form-textarea id="familyCV" v-model="model.familyCV"></b-form-textarea>
            </b-form-group>
          </b-row>
        </template>
      </iq-card>

      <iq-card>
        <template v-slot:body>
          <template v-if="isEdit">
            <b-row>
              <b-form-group class="col-md-4" :label="$t('EndDate')" label-for="endDate">
                <date-picker v-model="model.endDate"
                             :tag-name="'endDate'"
                             :is-required="false" />
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('EndDescription')" label-for="endDescription">
                <b-form-textarea id="endDescription" v-model="model.endDescription"></b-form-textarea>
              </b-form-group>
            </b-row>
          </template>
        </template>
      </iq-card>

      <modal-footer-button :set-is-submitting="isSubmitting"
                           @submit="submitNewCustomerDiagnose"></modal-footer-button>
    </form>
  </b-container>
</template>
<script>
  import customerDiagnoseServices from '../../../services/customerDiagnose'

  import DatePicker from '../../../components/shared/DatePicker'
  import { getToday } from '../../../utils/dateTimeExtensions';
  import DiagnoseIcd10 from '../../../components/customer/DiagnoseIcd10'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  export default {
    name: 'NewCustomerDiagnose',
    props: {
      customerId: String,
      id: String
    },
    components: {
      DatePicker,
      DiagnoseIcd10,
      ModalFooterButton
    },
    data() {
      return {
        model: {
          id: '',
          issueDate: null,
          issues: '',
          startDescription: null,
          preDiagnose: '',
          diagnose: '',
          startDate: getToday(),
          icdCode: '',
          icdCode2: '',
          icdCode3: '',
          physicalExamination: '',
          treatmentDate: '',
          treatment: '',
          pastTreatments: '',
          drugs: '',
          nutritionProgram: '',
          familyCV: '',
          cv: '',
          endDescription: '',
          endDate: '',
          customerID: null
        },
        icdList: [],
        isEdit: false,
        isSubmitting: false
      }

    },
    methods: {
      ChangeIcdCode(val, index) {
        if (index == 1)
          this.model.icdCode = val;
        else if (index == 2)
          this.model.icdCode2 = val;
        else
          this.model.icdCode3 = val;
      },
      async submitNewCustomerDiagnose() {
        const isValid = this.validate();
        if (isValid) {
          this.$toastr.error(this.$t("DiagnoseFormValidationErrorMessage"));
          return;
        }

        this.isSubmitting = true;

        customerDiagnoseServices.submitNewCustomerDiagnose(this.model).then((response) => {
          this.isSubmitting = false;
          if (response) {
            this.$toastr.success(this.$t("Success"));
            this.$router.push({ name: 'customerSummary.diagnoses' })
          }
          else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
      getDiagnose() {
        customerDiagnoseServices.getDiagnose(this.model.id).then((response) => {
          if (response) {
            this.model = response;
          }
        })
      },
      validate() {
        if (this.model.icdCode || this.model.icdCode2 || this.model.icdCode3) {
          return false;
        }
        else {
          return true;
        }
      }
    },
    mounted() {
      this.model.customerID = this.customerId;
      if (this.id && this.id.length > 0) {
        this.model.id = this.id
        this.getDiagnose();
        this.isEdit = true;
      }
    }
  }
</script>
