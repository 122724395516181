<template>
  <v-select style="margin-bottom: 1.5%;"
            transition="" v-model="selectedValue"
            @search="searchIcdList"
            :searchable="true"
            :reduce="s => s.id" label="name"
            :options="icdList"
            :placeholder="$t('StartTypingToFilter')">
  <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
</template>
<script>
  import CustomerDiagnoseServices from '../../services/customerDiagnose'

  export default {
    name: 'Diagnoselcd10',
    component: {
      CustomerDiagnoseServices
    },
    props: {
      value: {
        required: true
      },
      index: Number,
      isEdit: Boolean
    },
    data() {
      return {
        selectedValue: this.value,
        icdList: [],
      }

    },
    methods: {
      searchIcdList(search) {
        if (search.length >= 3) {
          this.getIcdList(search);
        }
      },
      getIcdList(keyword) {
        CustomerDiagnoseServices.getIcdList(keyword).then((response) => {
          if (response) {
            this.icdList = response;
          }
        })
      },

    },
    watch: {
      isEdit: function (val) {
        if (val && this.selectedValue)
          this.getIcdList(this.selectedValue);
      },
      value: function (val) {
        if (val) {
          this.selectedValue = val;
          if (this.isEdit)
            this.getIcdList(this.selectedValue);
        }
      },
      selectedValue: function (val) {
        this.$emit('ChangeIcdCode', val,this.index);
      }
    },
    mounted: function () {
      this.selectedValue = this.value;
      if (this.selectedValue && this.isEdit) {
        this.getIcdList(this.value);
      }
    }
  };
</script>
